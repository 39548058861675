import $ from "jquery";
import "slick-carousel";

function slickSlider($slider) {
  $slider.slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    cssEase: "linear",
    variableWidth: true,
    appendArrows: $(".events-slider-nav--desktop"),
    resposive: [
      {
        breakpoint: 900,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
    prevArrow:
      '<button type="button" class="slick-prev js-prev-button"></button>',
    nextArrow:
      '<button type="button" class="slick-next js-next-button"></button>',
  });
}

$(document).ready(function ($) {
  const $isVar2 = $(".is-style-upcoming-events-var-2").length > 0;
  if ($isVar2) {
    $(".js-events-content").each(function () {
      slickSlider($(this));
    });
  }
});
